<template style="height:100%;">
    <div style="height:100%;">
		<b-container style="height:100%;">
		  <div class="mt-4">
			<b-card :img-src="require('../assets/ontechu_small.png')" img-height="300px" img-alt="" img-left class="mb-3" header-class="article-title"
				title="Khalil El-Khatib">
				<b-card-text class="text-left">
                    Business and Information Technology Building, Mezzanine - Room 4014 <br />
                    North Oshawa <br />
                    2000 Simcoe Street North <br />
                    Oshawa, ON L1G 0C5 <br /> <br />

                    905.721.8668 ext. 5390  <br /> <br />

                    <a href="mailto:khalil.el-khatib@ontariotechu.ca">khalil.el-khatib@ontariotechu.ca</a> <br />
                    faculty.uoit.ca/elkhatib  <br />
				</b-card-text>
			</b-card>

          </div>
        </b-container>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
	name: 'Contact'
}
</script>
